@import 'bootstrap/dist/css/bootstrap.min.css';

.my-marker {
  background-image: url("../img/skull_96.png");
  background-repeat: no-repeat;
  background-size: 100%;
}
.my-marker-selected {
  background-image: url("../img/skull_96.png");
  filter: drop-shadow(0 0 1rem blue);
}
.my-marker-move {
  background-image: url("../img/skull_96_pin.png");
  filter: drop-shadow(0 0 1rem red);
}
.my-marker-movemove {
  background-image: url("../img/skull_96.png");
  filter: drop-shadow(0 0 1rem green);
}

.navbar-top {
  z-index: 1000;
  position: absolute;
  height: auto;
  width: 100%;
  padding: 0px 60px 0px 0px;
  margin-left: 60px;
}

.navbar-bottom {
  z-index: 1000;
  position: absolute;
  height: auto;
  bottom: 0;
  width: 100%;
}

.navbar-container {
  display: flex;
  justify-content: flex-end;
  padding: 25px;
}

.nav-alert {
  margin-left: 25px;
}
.nav-button:not(:first-child) {
  margin-left: 25px;
}
.person-marker {
  background-color: white;
  border-radius: 10px;
  height: 20px;
  width: 20px;
}
.inner-circle {
  background-color: blueviolet;
  border-radius: 8px;
  height: 16px;
  width: 16px;
  left: 2px;
  top: 2px;
  position: relative;
}

.spinner {
  animation: rotate linear 2s infinite;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.skull-marker-label-container {
  position: absolute;
  top: 105%;
  display: flex;
  justify-content: center;
  width: 100%;
}

.skull-marker-label-container-move {
  top: -20% ! important;
}

.skull-marker-label {
  background-color: #dadada;
  border-radius: 4px;
  color: black;
  font-weight: bold;
  box-shadow: 0 0 5px black;
  /* border: 2px black solid; */
  padding: 3px;
}

.menu {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
}

.menu > button {
  min-width: 45%;
}

.menu > button:not(:first-child){
  margin-top: 20px;
}

.menu > .modal-dialog > .modal-content {
  background-color: rgba(1,1,1,0.3) !important;
}

.menu > .modal-dialog > .modal-content > .modal-header {
  border-bottom: 1px solid rgba(0,0,0,0.2);
}

.skull-list {
  max-height: 45vh;
  overflow: auto;
}

.skull-list-entry {
  cursor: pointer;
  margin: 8px 20px;
  padding: 5px;
  background-color:rgb(234, 234, 234);
  border: 1px rgb(224, 224, 224) solid;
  border-radius: 5px;
  height: 64px;
  display: grid;
  grid-template-areas: "a c d" "a b d";
  grid-template-columns: auto 1fr auto;
  column-gap: 20px;
}

.tiny {
  font-size: smaller;
}
.muted {
  color: gray;
}
.skull-list-notes {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}